import './componentHook'

import VueRouter, { RouteConfig } from 'vue-router'

import { ClientConfiguration } from './client-config'
import Vue from 'vue'

/* eslint-disable @typescript-eslint/explicit-function-return-type  */

const uiKitComponent = () => import('../pages/ui-kit/ui-kit').then(({ UIKitComponent }) => UIKitComponent)
const notFoundComponent = () =>
  import('../components/not-found/not-found').then(({ NotFoundComponent }) => NotFoundComponent)

// PORTAL PAGES
const dashboardComponent = () =>
  import('../pages/dashboard/dashboard').then(({ DashboardComponent }) => DashboardComponent)
const targetCorrelationRecommendationComponent = () =>
  import('../pages/target-correlation-recommendation/target-correlation-recommendation').then(
    ({ TargetCorrelationRecommendationComponent }) => TargetCorrelationRecommendationComponent
  )
const evaluationComponent = () =>
  import('../pages/evaluation/evaluation').then(({ EvaluationComponent }) => EvaluationComponent)
const evaluationSurveyComponent = () =>
  import('../pages/evaluation-survey/evaluation-survey').then(
    ({ EvaluationSurveyComponent }) => EvaluationSurveyComponent
  )
const okrCompanyStrategyListComponent = () =>
  import('../pages/okr/company-strategy-list/okr-company-strategy-list').then(
    ({ OkrCompanyStrategyListComponent }) => OkrCompanyStrategyListComponent
  )

const okrDashboardComponent = () =>
  import('../pages/okr/dashboard/okr-dashboard').then(({ OkrDashboardComponent }) => OkrDashboardComponent)

const companyTreeComponent = () =>
  import('../pages/okr/company-tree/company-tree').then(({ CompanyTreeComponent }) => CompanyTreeComponent)

const myCreatedOkrListComponent = () =>
  import('../pages/okr/my-created-okr-list/my-created-okr-list').then(
    ({ MyCreatedOkrListComponent }) => MyCreatedOkrListComponent
  )

const okrDetailComponent = () =>
  import('../pages/okr/okr-detail/okr-detail').then(({ OkrDetailComponent }) => OkrDetailComponent)

const okrTeamDetailComponent = () =>
  import('../pages/okr/okr-team-detail/okr-team-detail').then(({ OkrTeamDetailComponent }) => OkrTeamDetailComponent)

const okrCompanyStrategyEditComponent = () =>
  import('../pages/okr/company-strategy-edit/okr-company-strategy-edit').then(
    ({ OkrCompanyStrategyEditComponent }) => OkrCompanyStrategyEditComponent
  )
const evaluationSurveyForEmployeeComponent = () =>
  import('../pages/evaluation-survey-for-employee/evaluation-survey-for-employee').then(
    ({ EvaluationSurveyForEmployeeComponent }) => EvaluationSurveyForEmployeeComponent
  )
const comparisonAnalysisComponent = () =>
  import('../pages/comparison-analysis/comparison-analysis').then(
    ({ ComparisonAnalysisComponent }) => ComparisonAnalysisComponent
  )
const targetDetailComponent = () =>
  import('../pages/target-detail/target-detail').then(({ TargetDetailComponent }) => TargetDetailComponent)
const notificationsComponent = () =>
  import('../pages/notifications/notifications').then(({ NotificationsComponent }) => NotificationsComponent)
const profileComponent = () => import('../pages/profile/profile').then(({ ProfileComponent }) => ProfileComponent)
const settingsComponent = () => import('../pages/settings/settings').then(({ SettingsComponent }) => SettingsComponent)

const threeSixtyDashboardComponent = () =>
  import('../pages/three-sixty/three-sixty-dashboard/three-sixty-dashboard').then(
    ({ ThreeSixtyDashboardComponent }) => ThreeSixtyDashboardComponent
  )
const threeSixtyMyEvaluatorsComponent = () =>
  import('../pages/three-sixty/three-sixty-my-evaluators/three-sixty-my-evaluators').then(
    ({ ThreeSixtyMyEvaluatorsComponent }) => ThreeSixtyMyEvaluatorsComponent
  )
const threeSixtyWaitingApprovalsComponent = () =>
  import('../pages/three-sixty/three-sixty-waiting-approvals/three-sixty-waiting-approvals').then(
    ({ ThreeSixtyWaitingApprovalsComponent }) => ThreeSixtyWaitingApprovalsComponent
  )
const threeSixtyMyEvaluationsComponent = () =>
  import('../pages/three-sixty/three-sixty-my-evaluations/three-sixty-my-evaluations').then(
    ({ ThreeSixtyMyEvaluationsComponent }) => ThreeSixtyMyEvaluationsComponent
  )
const threeSixtyEvaluationComponent = () =>
  import('../pages/three-sixty/three-sixty-evaluation/three-sixty-evaluation').then(
    ({ ThreeSixtyEvaluationComponent }) => ThreeSixtyEvaluationComponent
  )
const employeePerformanceFormSummaryComponent = () =>
  import('../pages/employee-performance-form-summary/employee-performance-form-summary').then(
    ({ EmployeePerformanceFormSummaryComponent }) => EmployeePerformanceFormSummaryComponent
  )
const employeePerformanceFormEditComponent = () =>
  import('../pages/employee-performance-form-edit/employee-performance-form-edit').then(
    ({ EmployeePerformanceFormEditComponent }) => EmployeePerformanceFormEditComponent
  )
const employeePerformanceFormEditTargetComponent = () =>
  import('../pages/employee-performance-form-target-edit/employee-performance-form-target-edit').then(
    ({ EmployeePerformanceFormEditTargetComponent }) => EmployeePerformanceFormEditTargetComponent
  )
const employeePerformanceFormTargetOfferComponent = () =>
  import('../pages/employee-performance-form-target-offer/employee-performance-form-target-offer').then(
    ({ EmployeePerformanceFormTargetOfferComponent }) => EmployeePerformanceFormTargetOfferComponent
  )
const employeePerformanceFormCompetenceEditComponent = () =>
  import('../pages/employee-performance-form-competence-edit/employee-performance-form-competence-edit').then(
    ({ EmployeePerformanceFormCompetenceEditComponent }) => EmployeePerformanceFormCompetenceEditComponent
  )
const employeePerformanceFormCompetenceDetailComponent = () =>
  import('../pages/employee-performance-form-competence-detail/employee-performance-form-competence-detail').then(
    ({ EmployeePerformanceFormCompetenceDetailComponent }) => EmployeePerformanceFormCompetenceDetailComponent
  )

const refreshCacheComponent = () =>
  import('../pages/refreshcache/refreshcache').then(({ RefreshCacheComponent }) => RefreshCacheComponent)

const performanceAllFormsListComponent = () =>
  import('../pages/employee-performance-form-edit/allforms/performance-form-edit-allforms').then(
    ({ PerformanceAllFormsListComponent }) => PerformanceAllFormsListComponent
  )

const tasksComponent = () => import('../pages/tasks/tasks').then(({ TasksComponent }) => TasksComponent)

const unauthorizedComponent = () =>
  import('../pages/unauthorized/unauthorized').then(({ UnauthorizedComponent }) => UnauthorizedComponent)

const threeSixtyPeriodSummaryComponent = () =>
  import('../pages/three-sixty/three-sixty-dashboard/three-sixty-period-summary/three-sixty-period-summary').then(
    ({ ThreeSixtyPeriodSummaryComponent }) => ThreeSixtyPeriodSummaryComponent
  )

// ADMIN PAGES
const adminDashBoardComponent = () =>
  import('../pages/admin/admin-dashboard/admin-dashboard').then(
    ({ AdminDashBoardComponent }) => AdminDashBoardComponent
  )
const okrTeamListComponent = () =>
  import('../pages/okr/team-list/okr-team-list').then(({ OkrTeamListComponent }) => OkrTeamListComponent)
const okrTeamEditComponent = () =>
  import('../pages/okr/team-edit/okr-team-edit').then(({ OkrTeamEditComponent }) => OkrTeamEditComponent)
const okrPeriodEditComponent = () =>
  import('../pages/okr/okr-period-edit/okr-period-edit').then(({ OkrPeriodEditComponent }) => OkrPeriodEditComponent)
const okrPeriodListComponent = () =>
  import('../pages/okr/okr-period-list/okr-period-list').then(({ OkrPeriodListComponent }) => OkrPeriodListComponent)
const assigningOkrTeamComponent = () =>
  import('../pages/okr/assigning-team/assigning-okr-team').then(
    ({ AssigningOkrTeamComponent }) => AssigningOkrTeamComponent
  )
const userModuleManagementComponent = () =>
  import('../pages/admin/user-module-management/user-module-management').then(
    ({ UserModuleManagementComponent }) => UserModuleManagementComponent
  )
const accessRequestComponent = () =>
  import('../pages/admin/access-request/access-request').then(({ AccessRequestComponent }) => AccessRequestComponent)
const accessRequestEditComponent = () =>
  import('../pages/admin/access-request-edit/access-request-edit').then(
    ({ AccessRequestEditComponent }) => AccessRequestEditComponent
  )
const accessRequestResultListComponent = () =>
  import('../pages/admin/access-request-result-list/access-request-result-list').then(
    ({ AccessRequestResultListComponent }) => AccessRequestResultListComponent
  )
const accessRequestResultEditComponent = () =>
  import('../pages/admin/access-request-result-edit/access-request-result-edit').then(
    ({ AccessRequestResultEditComponent }) => AccessRequestResultEditComponent
  )
const targetLibraryListComponent = () =>
  import('../pages/admin/target-library-list/target-library-list').then(
    ({ TargetLibraryListComponent }) => TargetLibraryListComponent
  )
const targetLibraryEditComponent = () =>
  import('../pages/admin/target-library-edit/target-library-edit').then(
    ({ TargetLibraryEditComponent }) => TargetLibraryEditComponent
  )
const targetLibraryGroupListComponent = () =>
  import('../pages/admin/target-library-group-list/target-library-group-list').then(
    ({ TargetLibraryGroupListComponent }) => TargetLibraryGroupListComponent
  )
const targetLibraryGroupEditComponent = () =>
  import('../pages/admin/target-library-group-edit/target-library-group-edit').then(
    ({ TargetLibraryGroupEditComponent }) => TargetLibraryGroupEditComponent
  )
const competenceLevelComponent = () =>
  import('../pages/admin/competence-level/competence-level').then(
    ({ CompetenceLevelComponent }) => CompetenceLevelComponent
  )
const competenceListComponent = () =>
  import('../pages/admin/competence-list/competence-list').then(
    ({ CompetenceListComponent }) => CompetenceListComponent
  )
const competenceEditComponent = () =>
  import('../pages/admin/competence-edit/competence-edit').then(
    ({ CompetenceEditComponent }) => CompetenceEditComponent
  )
const employeeSubLevelListComponent = () =>
  import('../pages/admin/employee-sub-level-list/employee-sub-level-list').then(
    ({ EmployeeSubLevelListComponent }) => EmployeeSubLevelListComponent
  )
const employeeSubLevelEditComponent = () =>
  import('../pages/admin/employee-sub-level-edit/employee-sub-level-edit').then(
    ({ EmployeeSubLevelEditComponent }) => EmployeeSubLevelEditComponent
  )
const employeeLevelListComponent = () =>
  import('../pages/admin/employee-level-list/employee-level-list').then(
    ({ EmployeeLevelListComponent }) => EmployeeLevelListComponent
  )
const employeeLevelEditComponent = () =>
  import('../pages/admin/employee-level-edit/employee-level-edit').then(
    ({ EmployeeLevelEditComponent }) => EmployeeLevelEditComponent
  )
const lockedEmployeesComponent = () =>
  import('../pages/admin/locked-employees/locked-employees').then(
    ({ LockedEmployeesComponent }) => LockedEmployeesComponent
  )
const competenceGroupListComponent = () =>
  import('../pages/admin/competence-group-list/competence-group-list').then(
    ({ CompetenceGroupListComponent }) => CompetenceGroupListComponent
  )
const competenceGroupEditComponent = () =>
  import('../pages/admin/competence-group-edit/competence-group-edit').then(
    ({ CompetenceGroupEditComponent }) => CompetenceGroupEditComponent
  )
const competenceMatrixComponent = () =>
  import('../pages/admin/competence-matrix/competence-matrix').then(
    ({ CompetenceMatrixComponent }) => CompetenceMatrixComponent
  )
const performanceFormTemplateTreeComponent = () =>
  import('../pages/admin/performance-form-template-tree/performance-form-template-tree').then(
    ({ PerformanceFormTemplateTreeComponent }) => PerformanceFormTemplateTreeComponent
  )
const performanceFormTemplateGroupListComponent = () =>
  import('../pages/admin/performance-form-template-group-list/performance-form-template-group-list').then(
    ({ PerformanceFormTemplateGroupListComponent }) => PerformanceFormTemplateGroupListComponent
  )
const performanceFormTemplateGroupEditComponent = () =>
  import('../pages/admin/performance-form-template-group-edit/performance-form-template-group-edit').then(
    ({ PerformanceFormTemplateGroupEditComponent }) => PerformanceFormTemplateGroupEditComponent
  )
const performanceFormTemplateListComponent = () =>
  import('../pages/admin/performance-form-template-list/performance-form-template-list').then(
    ({ PerformanceFormTemplateListComponent }) => PerformanceFormTemplateListComponent
  )
const assigningPerformanceFormTemplateComponent = () =>
  import('../pages/admin/assigning-performance-form-template/assigning-performance-form-template').then(
    ({ AssigningPerformanceFormTemplateComponent }) => AssigningPerformanceFormTemplateComponent
  )
const employeeGroupListComponent = () =>
  import('../pages/admin/employee-group-list/employee-group-list').then(
    ({ EmployeeGroupListComponent }) => EmployeeGroupListComponent
  )
const employeeGroupEditComponent = () =>
  import('../pages/admin/employee-group-edit/employee-group-edit').then(
    ({ EmployeeGroupEditComponent }) => EmployeeGroupEditComponent
  )
const companyPrioritiesLibraryListComponent = () =>
  import('../pages/admin/company-priorities-library-list/company-priorities-library-list').then(
    ({ CompanyPrioritiesLibraryListComponent }) => CompanyPrioritiesLibraryListComponent
  )
const companyPrioritiesLibraryEditComponent = () =>
  import('../pages/admin/company-priorities-library-edit/company-priorities-library-edit').then(
    ({ CompanyPrioritiesLibraryEditComponent }) => CompanyPrioritiesLibraryEditComponent
  )

const performanceFormPeriodApprovalComponent = () =>
  import('../pages/admin/performance-form-period-approval-edit/performance-form-period-approval-edit').then(
    ({ PerformanceFormPeriodApprovalComponent }) => PerformanceFormPeriodApprovalComponent
  )

const performanceApprovalTypesListComponent = () =>
  import('../pages/admin/performance-approval-types-list/performance-approval-types-list').then(
    ({ PerformanceApprovalTypesListComponent }) => PerformanceApprovalTypesListComponent
  )
const performanceApprovalTypesEditComponent = () =>
  import('../pages/admin/performance-approval-types-edit/performance-approval-types-edit').then(
    ({ PerformanceApprovalTypesEditComponent }) => PerformanceApprovalTypesEditComponent
  )
const performanceApprovalFlowListComponent = () =>
  import('../pages/admin/performance-approval-flow-list/performance-approval-flow-list').then(
    ({ PerformanceApprovalFlowListComponent }) => PerformanceApprovalFlowListComponent
  )
const performanceApprovalFlowEditComponent = () =>
  import('../pages/admin/performance-approval-flow-edit/performance-approval-flow-edit').then(
    ({ PerformanceApprovalFlowEditComponent }) => PerformanceApprovalFlowEditComponent
  )
const notificationListComponent = () =>
  import('../pages/admin/notification-list/notification-list').then(
    ({ NotificationListComponent }) => NotificationListComponent
  )
const notificationListMultiLanguageComponent = () =>
  import('../pages/admin/notification-list-multilanguage/notification-list-multilanguage').then(
    ({ NotificationListMultiLanguageComponent }) => NotificationListMultiLanguageComponent
  )
const termsOfUseComponent = () =>
  import('../pages/admin/terms-of-use/terms-of-use').then(({ TermsOfUseComponent }) => TermsOfUseComponent)
const clarificationTextComponent = () =>
  import('../pages/admin/clarification-text/clarification-text').then(
    ({ ClarificationTextComponent }) => ClarificationTextComponent
  )
const notificationEditComponent = () =>
  import('../pages/admin/notification-edit/notification-edit').then(
    ({ NotificationEditComponent }) => NotificationEditComponent
  )
const notificationEditMultiLanguageComponent = () =>
  import('../pages/admin/notification-edit-multilanguage/notification-edit-multilanguage').then(
    ({ NotificationEditMultiLanguageComponent }) => NotificationEditMultiLanguageComponent
  )
const userGroupDetailComponent = () =>
  import('../pages/admin/usergroup-detail/usergroup-detail').then(
    ({ UserGroupDetailComponent }) => UserGroupDetailComponent
  )

const evaluationPeriodStartComponent = () =>
  import('../pages/admin/evaluation-period-start/evaluation-period-start').then(
    ({ EvaluationPeriodStartComponent }) => EvaluationPeriodStartComponent
  )

const competenceEvaludationScaleListComponent = () =>
  import('../pages/admin/competence-evaluation-scale-list/competence-evaluation-scale-list').then(
    ({ CompetenceEvaluationScaleListComponent }) => CompetenceEvaluationScaleListComponent
  )
const threeSixtyCompetenceEvaluationScaleListComponent = () =>
  import(
    '../pages/three-sixty/three-sixty-competence-evaluation-scale-list/three-sixty-competence-evaluation-scale-list'
  ).then(({ ThreeSixtyCompetenceEvaluationScaleListComponent }) => ThreeSixtyCompetenceEvaluationScaleListComponent)
const performanceEvaluationScaleListComponent = () =>
  import('../pages/admin/performance-evaluation-scale-list/performance-evaluation-scale-list').then(
    ({ PerformanceEvaluationScaleListComponent }) => PerformanceEvaluationScaleListComponent
  )

const competenceEvaludationScaleEditComponent = () =>
  import('../pages/admin/competence-evaluation-scale-edit/competence-evaluation-scale-edit').then(
    ({ CompetenceEvaluationScaleEditComponent }) => CompetenceEvaluationScaleEditComponent
  )

const threeSixtyCompetenceEvaluationScaleEditComponent = () =>
  import(
    '../pages/three-sixty/three-sixty-competence-evaluation-scale-edit/three-sixty-competence-evaluation-scale-edit'
  ).then(({ ThreeSixtyCompetenceEvaluationScaleEditComponent }) => ThreeSixtyCompetenceEvaluationScaleEditComponent)

const performanceEvaluationScaleEditComponent = () =>
  import('../pages/admin/performance-evaluation-scale-edit/performance-evaluation-scale-edit').then(
    ({ PerformanceEvaluationScaleEditComponent }) => PerformanceEvaluationScaleEditComponent
  )

const instantMobileNotificationComponent = () =>
  import('../pages/admin/instant-mobile-notification/instant-mobile-notification').then(
    ({ InstantMobileNotificationComponent }) => InstantMobileNotificationComponent
  )
const instantNotificationComponent = () =>
  import('../pages/admin/instant-notification/instant-notification').then(
    ({ InstantNotificationComponent }) => InstantNotificationComponent
  )
const comparisonGroupListComponent = () =>
  import('../pages/admin/comparison-group-list/comparison-group-list').then(
    ({ ComparisonGroupListComponent }) => ComparisonGroupListComponent
  )
const comparisonGroupEditComponent = () =>
  import('../pages/admin/comparison-group-edit/comparison-group-edit').then(
    ({ ComparisonGroupEditComponent }) => ComparisonGroupEditComponent
  )
const assigningComparisonGroupComponent = () =>
  import('../pages/admin/assigning-comparison-group/assigning-comparison-group').then(
    ({ AssigningComparisonGroupComponent }) => AssigningComparisonGroupComponent
  )

const evaluatorRolesListComponent = () =>
  import('../pages/admin/evaluator-roles-list/evaluator-roles-list').then(
    ({ EvaluatorRolesListComponent }) => EvaluatorRolesListComponent
  )

const evaluatorRolesEditComponent = () =>
  import('../pages/admin/evaluator-roles-edit/evaluator-roles-edit').then(
    ({ EvaluatorRolesEditComponent }) => EvaluatorRolesEditComponent
  )

const evaluation360GroupListComponent = () =>
  import('../pages/admin/evaluation-360-group-list/evaluation-360-group-list').then(
    ({ Evaluation360GroupListComponent }) => Evaluation360GroupListComponent
  )

const evaluation360GroupEditComponent = () =>
  import('../pages/admin/evaluation-360-group-edit/evaluation-360-group-edit').then(
    ({ Evaluation360GroupEditComponent }) => Evaluation360GroupEditComponent
  )

const period360ListComponent = () =>
  import('../pages/admin/period-360-list/period-360-list').then(({ Period360ListComponent }) => Period360ListComponent)

const performanceFormOperationYearListComponent = () =>
  import('../pages/admin/performance-form-operation-year-list/performance-form-operation-year-list').then(
    ({ PerformanceFormOperationYearListComponent }) => PerformanceFormOperationYearListComponent
  )
const performanceFormOperationYearEditComponent = () =>
  import('../pages/admin/performance-form-operation-year-edit/performance-form-operation-year-edit').then(
    ({ PerformanceFormOperationYearEditComponent }) => PerformanceFormOperationYearEditComponent
  )
const period360EditComponent = () =>
  import('../pages/admin/period-360-edit/period-360-edit').then(({ Period360EditComponent }) => Period360EditComponent)

const evaluation360GroupAssignmentComponent = () =>
  import('../pages/admin/evaluation-360-group-assignment/evaluation-360-group-assignment').then(
    ({ Evaluation360GroupAssignmentComponent }) => Evaluation360GroupAssignmentComponent
  )

const evaluation360Period = () =>
  import('../pages/admin/evaluation-360-period-start-end/evaluation-360-period-start-end').then(
    ({ Evaluation360Period }) => Evaluation360Period
  )

const scheduledJobListComponent = () =>
  import('../pages/admin/scheduled-job-list/scheduled-job-list').then(
    ({ ScheduledJobListComponent }) => ScheduledJobListComponent
  )

const companyCustomizationParameter = () =>
  import('../pages/admin/company-customization-parameter/company-customization-parameter').then(
    ({ CompanyCustomizationParameter }) => CompanyCustomizationParameter
  )

const performanceFormDataTransferComponent = () =>
  import('../pages/admin/performance-form-data-transfer/performance-form-data-transfer').then(
    ({ PerformanceFormDataTransferComponent }) => PerformanceFormDataTransferComponent
  )

const performanceFormDataTransferInfoComponent = () =>
  import('../pages/admin/performance-form-data-transfer-info/performance-form-data-transfer-info').then(
    ({ PerformanceFormDataTransferInfoComponent }) => PerformanceFormDataTransferInfoComponent
  )

const performanceFormDataTransferSearchComponent = () =>
  import('../pages/admin/performance-form-data-transfer-search/performance-form-data-transfer-search').then(
    ({ PerformanceFormDataTransferSearchComponent }) => PerformanceFormDataTransferSearchComponent
  )

const reportComponent = () => import('../pages/admin/reports/reports').then(({ ReportComponent }) => ReportComponent)

const usageStatisticsComponent = () =>
  import('../pages/admin/usage-statistics/usage-statistics').then(
    ({ UsageStatisticsComponent }) => UsageStatisticsComponent
  )

const userStatisticsComponent = () =>
  import('../pages/admin/user-statistics/user-statistics').then(
    ({ UserStatisticsComponent }) => UserStatisticsComponent
  )

const totalUsageStatisticsComponent = () =>
  import('../pages/admin/total-usage-statistics/total-usage-statistics').then(
    ({ TotalUsageStatisticsComponent }) => TotalUsageStatisticsComponent
  )

const supportComponent = () => import('../pages/admin/support/support').then(({ SupportComponent }) => SupportComponent)

const threeSixtyEvaluatorsListComponent = () =>
  import('../pages/admin/three-sixty-evaluators-list/three-sixty-evaluators-list').then(
    ({ ThreeSixtyEvaluatorsListComponent }) => ThreeSixtyEvaluatorsListComponent
  )

const threeSixtyEvaluatorsEditComponent = () =>
  import('../pages/admin/three-sixty-evaluators-edit/three-sixty-evaluators-edit').then(
    ({ ThreeSixtyEvaluatorsEditComponent }) => ThreeSixtyEvaluatorsEditComponent
  )

const performanceFormDetailTagListComponent = () =>
  import('../pages/admin/performance-form-detail-tag-list/performance-form-detail-tag-list').then(
    ({ PerformanceFormDetailTagListComponent }) => PerformanceFormDetailTagListComponent
  )

const performanceFormDetailTagEditComponent = () =>
  import('../pages/admin/performance-form-detail-tag-edit/performance-form-detail-tag-edit').then(
    ({ PerformanceFormDetailTagEditComponent }) => PerformanceFormDetailTagEditComponent
  )

const previousPerformanceFormComponent = () =>
  import('../pages/previous-performance-form/previous-performance-form').then(
    ({ PreviousPerformanceFormComponent }) => PreviousPerformanceFormComponent
  )

const companyCustomizationParameterList = () =>
  import('../pages/admin/company-customization-parameter-list/company-customization-parameter-list').then(
    ({ CompanyCustomizationParameterList }) => CompanyCustomizationParameterList
  )

const announcement = () =>
  import('../pages/admin/announcement/announcement').then(({ AnnouncementComponent }) => AnnouncementComponent)

const threeSixtyEmployeeLevelComponent = () =>
  import('../pages/admin/three-sixty-employee-level/three-sixty-employee-level').then(
    ({ ThreeSixtyEmployeeLevelComponent }) => ThreeSixtyEmployeeLevelComponent
  )

const threeSixtyEmployeeApprovalComponent = () =>
  import('../pages/admin/three-sixty-employee-approval/three-sixty-employee-approval').then(
    ({ ThreeSixtyEmployeeApprovalComponent }) => ThreeSixtyEmployeeApprovalComponent
  )
const threeSixtyAllFormsComponent = () =>
  import('../pages/admin/three-sixty-form-edit-allforms/three-sixty-form-edit-allforms').then(
    ({ ThreeSixtyAllFormsComponent }) => ThreeSixtyAllFormsComponent
  )
const announcementEditComponent = () =>
  import('../pages/admin/announcement-edit/announcement-edit').then(
    ({ AnnouncementEditComponent }) => AnnouncementEditComponent
  )

const companySelectComponent = () =>
  import('../pages/admin/company-select/company-select').then(({ CompanySelectComponent }) => CompanySelectComponent)

const applicationSelectComponent = () =>
  import('../pages/admin/application-select/application-select').then(
    ({ ApplicationSelectComponent }) => ApplicationSelectComponent
  )

const threeSixtyEmployeeEvaluatorComponent = () =>
  import('../pages/admin/three-sixty-employee-evaluator/three-sixty-employee-evaluator').then(
    ({ ThreeSixtyEmployeeEvaluatorComponent }) => ThreeSixtyEmployeeEvaluatorComponent
  )

const okrTeamObjectiveEditComponent = () =>
  import('../pages/okr/team-objective-edit/team-objective-edit').then(
    ({ OkrTeamObjectiveEditComponent }) => OkrTeamObjectiveEditComponent
  )

const mandotaryTargetEvaluationResultComponent = () =>
  import('../pages/admin/mandotary-target-evaluation-result/mandotary-target-evaluation-result').then(
    ({ MandotaryTargetEvaluationResultComponent }) => MandotaryTargetEvaluationResultComponent
  )

const integrationHistoryListComponent = () =>
  import('../pages/admin/integration-history-list/integration-history-list').then(
    ({ IntegrationHistoryListComponent }) => IntegrationHistoryListComponent
  )
const calibrationComponent = () =>
  import('../pages/admin/calibration/calibration').then(({ CalibrationComponent }) => CalibrationComponent)

Vue.use(VueRouter)

export const createRoutes: () => RouteConfig[] = () => [
  // General Routes Start
  {
    path: '/refresh-cache',
    component: refreshCacheComponent
  },
  {
    path: '/unauthorized',
    component: unauthorizedComponent
  },
  {
    path: '/ui-kit',
    component: uiKitComponent
  },
  {
    path: '/',
    component: dashboardComponent
  },
  {
    path: '/404',
    component: notFoundComponent
  },
  {
    path: '*',
    component: notFoundComponent
  },
  // General Routes End

  // Portal Routes Begin
  {
    path: '/employee-performance-form-summary',
    component: employeePerformanceFormSummaryComponent
  },
  {
    path: '/employee-performance-form-edit',
    component: employeePerformanceFormEditComponent
  },
  {
    path: '/employee-performance-form-edit?formId=:performanceFormId',
    component: employeePerformanceFormEditComponent
  },
  {
    path: '/employee-performance-form-target-edit',
    component: employeePerformanceFormEditTargetComponent
  },
  {
    path: '/employee-performance-form-target-offer',
    component: employeePerformanceFormTargetOfferComponent
  },
  {
    path: '/employee-performance-form-competence-edit',
    component: employeePerformanceFormCompetenceEditComponent
  },
  {
    path: '/employee-performance-form-competence-detail',
    component: employeePerformanceFormCompetenceDetailComponent
  },
  {
    path: '/dashboard',
    component: dashboardComponent
  },
  {
    path: '/target-correlation-recommendation',
    component: targetCorrelationRecommendationComponent
  },
  {
    path: '/evaluation',
    component: evaluationComponent
  },
  {
    path: '/evaluation?formId=:performanceFormId',
    component: evaluationComponent
  },
  {
    path: '/previous-performance-form',
    component: previousPerformanceFormComponent
  },
  {
    path: '/comparison-analysis',
    component: comparisonAnalysisComponent
  },
  {
    path: '/target-detail',
    component: targetDetailComponent
  },
  {
    path: '/evaluation-detail',
    component: targetDetailComponent
  },
  {
    path: '/evaluation-survey',
    component: evaluationSurveyComponent
  },
  {
    path: '/okr-company-strategy-list',
    component: okrCompanyStrategyListComponent,
    isAdminRoute: true
  },
  {
    path: '/okr-company-strategy-edit',
    component: okrCompanyStrategyEditComponent,
    isAdminRoute: true
  },
  {
    path: '/evaluation-survey-for-employee',
    component: evaluationSurveyForEmployeeComponent
  },
  {
    path: '/notifications',
    component: notificationsComponent
  },
  {
    path: '/profile',
    component: profileComponent
  },
  {
    path: '/settings',
    component: settingsComponent
  },
  {
    path: '/three-sixty-dashboard',
    component: threeSixtyDashboardComponent
  },
  {
    path: '/three-sixty-period-summary',
    component: threeSixtyPeriodSummaryComponent
  },
  {
    path: '/three-sixty-my-evaluators',
    component: threeSixtyMyEvaluatorsComponent
  },
  {
    path: '/three-sixty-waiting-approvals',
    component: threeSixtyWaitingApprovalsComponent
  },
  {
    path: '/three-sixty-my-evaluations',
    component: threeSixtyMyEvaluationsComponent
  },
  {
    path: '/three-sixty-evaluation',
    component: threeSixtyEvaluationComponent
  },
  {
    path: '/tasks',
    component: tasksComponent
  },
  {
    path: '/okr-detail',
    component: okrDetailComponent
  },
  {
    path: '/okr-team-detail',
    component: okrTeamDetailComponent
  },
  // Portal Routes End

  // Admin Routes Start
  {
    path: '/admin-dashboard',
    component: adminDashBoardComponent,
    isAdminRoute: true
  },
  {
    path: '/user-module-management',
    component: userModuleManagementComponent,
    isAdminRoute: true
  },
  {
    path: '/access-request',
    component: accessRequestComponent,
    isAdminRoute: true
  },
  {
    path: '/access-request-edit',
    component: accessRequestEditComponent,
    isAdminRoute: true
  },
  {
    path: '/target-library-list',
    component: targetLibraryListComponent,
    isAdminRoute: true
  },
  {
    path: '/assigning-okr-team',
    component: assigningOkrTeamComponent,
    isAdminRoute: true
  },
  {
    path: '/okr-team-edit',
    component: okrTeamEditComponent,
    isAdminRoute: true
  },
  {
    path: '/okr-team-list',
    component: okrTeamListComponent,
    isAdminRoute: true
  },
  {
    path: '/okr-period-list',
    component: okrPeriodListComponent,
    isAdminRoute: true
  },
  {
    path: '/okr-period-edit',
    component: okrPeriodEditComponent,
    isAdminRoute: true
  },
  {
    path: '/okr-dashboard',
    component: okrDashboardComponent
  },
  {
    path: '/company-tree',
    component: companyTreeComponent
  },
  {
    path: '/my-created-okr-list',
    component: myCreatedOkrListComponent
  },
  {
    path: '/team-objective-edit',
    component: okrTeamObjectiveEditComponent
  },
  {
    path: '/access-request-result-list',
    component: accessRequestResultListComponent,
    isAdminRoute: true
  },
  {
    path: '/access-request-result-edit',
    component: accessRequestResultEditComponent,
    isAdminRoute: true
  },
  {
    path: '/target-library-edit',
    component: targetLibraryEditComponent,
    isAdminRoute: true
  },
  {
    path: '/target-library-group-list',
    component: targetLibraryGroupListComponent,
    isAdminRoute: true
  },
  {
    path: '/target-library-group-edit',
    component: targetLibraryGroupEditComponent,
    isAdminRoute: true
  },
  {
    path: '/competence-level',
    component: competenceLevelComponent,
    isAdminRoute: true
  },
  {
    path: '/competence-list',
    component: competenceListComponent,
    isAdminRoute: true
  },
  {
    path: '/competence-edit',
    component: competenceEditComponent,
    isAdminRoute: true
  },
  {
    path: '/employee-level-list',
    component: employeeLevelListComponent,
    isAdminRoute: true
  },
  {
    path: '/employee-level-edit',
    component: employeeLevelEditComponent,
    isAdminRoute: true
  },
  {
    path: '/locked-employees',
    component: lockedEmployeesComponent,
    isAdminRoute: true
  },
  {
    path: '/employee-sub-level-list',
    component: employeeSubLevelListComponent,
    isAdminRoute: true
  },
  {
    path: '/employee-sub-level-edit',
    component: employeeSubLevelEditComponent,
    isAdminRoute: true
  },
  {
    path: '/employee-performance-form-edit-allforms',
    component: performanceAllFormsListComponent,
    isAdminRoute: true
  },
  {
    path: '/competence-group-list',
    component: competenceGroupListComponent,
    isAdminRoute: true
  },
  {
    path: '/competence-group-edit',
    component: competenceGroupEditComponent,
    isAdminRoute: true
  },
  {
    path: '/competence-matrix',
    component: competenceMatrixComponent,
    isAdminRoute: true
  },
  {
    path: '/company-priorities-library-edit',
    component: companyPrioritiesLibraryEditComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-form-template-tree',
    component: performanceFormTemplateTreeComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-form-template-group-list',
    component: performanceFormTemplateGroupListComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-form-template-group-edit',
    component: performanceFormTemplateGroupEditComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-form-template-list',
    component: performanceFormTemplateListComponent,
    isAdminRoute: true
  },
  {
    path: '/assigning-performance-form-template',
    component: assigningPerformanceFormTemplateComponent,
    isAdminRoute: true
  },
  {
    path: '/employee-group-list',
    component: employeeGroupListComponent,
    isAdminRoute: true
  },
  {
    path: '/employee-group-edit',
    component: employeeGroupEditComponent,
    isAdminRoute: true
  },

  {
    path: '/company-priorities-library-list',
    component: companyPrioritiesLibraryListComponent,
    isAdminRoute: true
  },
  {
    path: '/terms-of-use',
    component: termsOfUseComponent,
    isAdminRoute: true
  },
  {
    path: '/clarification-text',
    component: clarificationTextComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-form-period-approval-edit',
    component: performanceFormPeriodApprovalComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-approval-types-list',
    component: performanceApprovalTypesListComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-approval-types-edit',
    component: performanceApprovalTypesEditComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-approval-flow-list',
    component: performanceApprovalFlowListComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-approval-flow-edit',
    component: performanceApprovalFlowEditComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-approval-types-list',
    component: performanceApprovalTypesListComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-approval-types-edit',
    component: performanceApprovalTypesEditComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-approval-flow-list',
    component: performanceApprovalFlowListComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-approval-flow-edit',
    component: performanceApprovalFlowEditComponent,
    isAdminRoute: true
  },
  {
    path: '/notification-list',
    component: notificationListComponent,
    isAdminRoute: true
  },
  {
    path: '/notification-list-multilanguage',
    component: notificationListMultiLanguageComponent,
    isAdminRoute: true
  },
  {
    path: '/notification-edit',
    component: notificationEditComponent,
    isAdminRoute: true
  },
  {
    path: '/notification-edit-multilanguage',
    component: notificationEditMultiLanguageComponent,
    isAdminRoute: true
  },
  {
    path: '/usergroup-detail',
    component: userGroupDetailComponent,
    isAdminRoute: true
  },
  {
    path: '/evaluation-period-start',
    component: evaluationPeriodStartComponent,
    isAdminRoute: true
  },
  {
    path: '/competence-evaluation-scale-list',
    component: competenceEvaludationScaleListComponent,
    isAdminRoute: true
  },
  {
    path: '/three-sixty-competence-evaluation-scale-list',
    component: threeSixtyCompetenceEvaluationScaleListComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-evaluation-scale-list',
    component: performanceEvaluationScaleListComponent,
    isAdminRoute: true
  },
  {
    path: '/competence-evaluation-scale-edit',
    component: competenceEvaludationScaleEditComponent,
    isAdminRoute: true
  },
  {
    path: '/three-sixty-competence-evaluation-scale-edit',
    component: threeSixtyCompetenceEvaluationScaleEditComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-evaluation-scale-edit',
    component: performanceEvaluationScaleEditComponent,
    isAdminRoute: true
  },
  {
    path: '/instant-mobile-notification',
    component: instantMobileNotificationComponent,
    isAdminRoute: true
  },
  {
    path: '/instant-notification',
    component: instantNotificationComponent,
    isAdminRoute: true
  },
  {
    path: '/mandotary-target-evaluation-result',
    component: mandotaryTargetEvaluationResultComponent,
    isAdminRoute: true
  },
  {
    // Aşağıdaki syntax daha okunaklı geldi
    // Yine yukarıdaki gibi lazy davranıyor
    // Tek fark, ".ts" içerisinde componenti "default export" etmek gerekiyor
    path: '/performance-approval-substitution',
    component: () => import('../pages/admin/performance-approval-substitution/performance-approval-substitution'),
    isAdminRoute: true
  },
  {
    path: '/evaluation-question-list',
    component: () => import('../pages/admin/evaluation-question-list/evaluation-question-list'),
    isAdminRoute: true
  },
  {
    path: '/evaluation-question-edit',
    component: () => import('../pages/admin/evaluation-question-edit/evaluation-question-edit'),
    isAdminRoute: true
  },
  {
    path: '/evaluation-question-list-for-employee',
    component: () =>
      import('../pages/admin/evaluation-question-list-for-employee/evaluation-question-list-for-employee'),
    isAdminRoute: true
  },
  {
    path: '/evaluation-question-edit-for-employee',
    component: () =>
      import('../pages/admin/evaluation-question-edit-for-employee/evaluation-question-edit-for-employee'),
    isAdminRoute: true
  },
  {
    path: '/reports',
    component: reportComponent,
    isAdminRoute: true
  },
  {
    path: '/usage-statistics',
    component: usageStatisticsComponent,
    isAdminRoute: true
  },
  {
    path: '/user-statistics',
    component: userStatisticsComponent,
    isAdminRoute: true
  },
  {
    path: '/total-usage-statistics',
    component: totalUsageStatisticsComponent,
    isAdminRoute: true
  },
  {
    path: '/support',
    component: supportComponent,
    isAdminRoute: true
  },
  {
    path: '/comparison-group-list',
    component: comparisonGroupListComponent,
    isAdminRoute: true
  },
  {
    path: '/comparison-group-edit',
    component: comparisonGroupEditComponent,
    isAdminRoute: true
  },
  {
    path: '/assigning-comparison-group',
    component: assigningComparisonGroupComponent,
    isAdminRoute: true
  },
  {
    path: '/evaluator-roles-list',
    component: evaluatorRolesListComponent,
    isAdminRoute: true
  },
  {
    path: '/evaluator-roles-edit',
    component: evaluatorRolesEditComponent,
    isAdminRoute: true
  },
  {
    path: '/evaluation-360-group-list',
    component: evaluation360GroupListComponent,
    isAdminRoute: true
  },
  {
    path: '/evaluation-360-group-edit',
    component: evaluation360GroupEditComponent,
    isAdminRoute: true
  },
  {
    path: '/period-360-list',
    component: period360ListComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-form-operation-year-list',
    component: performanceFormOperationYearListComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-form-operation-year-edit',
    component: performanceFormOperationYearEditComponent,
    isAdminRoute: true
  },
  {
    path: '/period-360-edit',
    component: period360EditComponent,
    isAdminRoute: true
  },
  {
    path: '/evaluation-360-group-assignment',
    component: evaluation360GroupAssignmentComponent,
    isAdminRoute: true
  },
  {
    path: '/evaluation-360-period-start-end',
    component: evaluation360Period,
    isAdminRoute: true
  },
  {
    path: '/three-sixty-evaluators-list',
    component: threeSixtyEvaluatorsListComponent,
    isAdminRoute: true
  },
  {
    path: '/three-sixty-evaluators-edit',
    component: threeSixtyEvaluatorsEditComponent,
    isAdminRoute: true
  },
  {
    path: '/scheduled-job-list',
    component: scheduledJobListComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-form-detail-tag-list',
    component: performanceFormDetailTagListComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-form-detail-tag-edit',
    component: performanceFormDetailTagEditComponent,
    isAdminRoute: true
  },
  {
    path: '/company-customization-parameter',
    component: companyCustomizationParameter,
    isAdminRoute: true
  },
  {
    path: '/performance-form-data-transfer',
    component: performanceFormDataTransferComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-form-data-transfer-info',
    component: performanceFormDataTransferInfoComponent,
    isAdminRoute: true
  },
  {
    path: '/performance-form-data-transfer-search',
    component: performanceFormDataTransferSearchComponent,
    isAdminRoute: true
  },
  {
    path: '/company-customization-parameter-list',
    component: companyCustomizationParameterList,
    isAdminRoute: true
  },
  {
    path: '/announcement',
    component: announcement,
    isAdminRoute: true
  },
  {
    path: '/three-sixty-employee-level',
    component: threeSixtyEmployeeLevelComponent,
    isAdminRoute: true
  },
  {
    path: '/three-sixty-employee-approval',
    component: threeSixtyEmployeeApprovalComponent,
    isAdminRoute: true
  },
  {
    path: '/three-sixty-employee-evaluator',
    component: threeSixtyEmployeeEvaluatorComponent,
    isAdminRoute: true
  },
  {
    path: '/announcement-edit',
    component: announcementEditComponent,
    isAdminRoute: true
  },
  {
    path: '/company-select',
    component: companySelectComponent,
    isAdminRoute: true
  },
  {
    path: '/application-select',
    component: applicationSelectComponent,
    isAdminRoute: true
  },
  {
    path: '/three-sixty-form-edit-allforms',
    component: threeSixtyAllFormsComponent,
    isAdminRoute: true
  },
  {
    path: '/integration-history-list',
    component: integrationHistoryListComponent,
    isAdminRoute: true
  },
  {
    path: '/calibration',
    component: calibrationComponent,
    isAdminRoute: true
  }

  // Admin Routes End
]

export const createRouter = (): VueRouter => {
  const routes = createRoutes()
  return new VueRouter({
    base: process.env.CLIENT_APP_BASE_PATH || ClientConfiguration.clientConfig.CLIENT_APP_BASE_PATH,
    mode: 'hash',
    routes: routes
  })
}
